import P5 from "p5";
import { triangleAtPoint } from "./ShapeWrappers";
import {sizeModifier} from "./sketch";

abstract class Orbit {

  /**
   * Makes a object orbit around a x/y-position.
   *
   * @param {number} x x-axis of point to orbit around
   * @param {number} y y-axis of point to orbit around
   * @param {number} theta angle value that is iterated upon to give a object
   * its orbit and keep track where it is in it
   * @param {*} obj object to put in orbit
   * @param {number} width how wide the orbit shall be
   * @param {number} height how wide the orbit shall be
   * @param {boolean} [zOrbit=false] if a object shall simulate
   *  being far away by modifying the size depending on z-changes
   * @memberof Orbit
   */
  public orbit(x:number, y:number, theta:number, obj:any, width:number, height:number, zOrbit=false):void {
    if(obj.orbitStop > 0) return;

    obj.o.orbitTheta += theta;

    const objT = obj.o.orbitTheta;


    const _x = x + width * Math.cos(objT);
    const _y = y + height * Math.sin(objT);

    obj.vector.x = _x;
    obj.vector.y = _y;
    if(zOrbit && obj.m != undefined) {
      obj.m = (obj.d * Math.sin(objT) > 0 )? obj.d * Math.sin(objT) * 1.5 : obj.d * Math.sin(objT);
      obj.vector.z = (obj.d * Math.sin(objT) > 0 )? obj.d * Math.sin(objT) * 1.5 : obj.d * Math.sin(objT);
    }
  }
}

export class OrbitTrajectory {
  _p5: P5;
  type: string;
  x: number;
  y: number;
  speed: number;
  width: number;
  height: number;
  zOrbit: boolean;
  orbitTheta: number;
  configObj: Record<string, any>;

  /**
   * Creates an instance of OrbitTrajectory.
   * @param {string} type what type of orbit trajectory it is. Types supported
   * are:
   * "center", orbit the center of the screen.
   * "object", orbit the center of an object. If "object", the object in question
   * will need to be supplied in configObj as "obj: <object>".
   * @param {number} diameter how wide of an circle the orbit is
   * @param {number} speed how fast the orbit should be
   * @param {Record<string, any>} [configObj={}] an object with arguments
   * for go with or overriding the defaults. Possible values to put in are
   * "width", "height", "zOrbit", "startPos" and "obj"
   * @memberof OrbitTrajectory
   */
  constructor(p5: P5, type:string, diameter:number, speed:number, configObj:Record<string, any> = {}) {
    this._p5 = p5;
    this.type = type;
    this.speed = speed;
    this.width = configObj && configObj.width || diameter;
    this.height = configObj && configObj.height || diameter;
    this.zOrbit = configObj && configObj.zOrbit || false;
    this.orbitTheta = configObj && configObj.startPos || 0;
    this.configObj = configObj;
    this.updateTrajectory();
  }

  public updateTrajectory():void {
    if(this.type == "center") {
      this.x = this._p5.windowWidth / 2;
      this.y = this._p5.windowHeight / 2;
    } else if(this.type == "object") {
      this.x = this.configObj.obj.vector.x;
      this.y = this.configObj.obj.vector.y;
    }
  }
}

export class Planet extends Orbit {

  _p5:P5;
  private img:P5.Image
  vector:P5.Vector;
  background:boolean;
  outlineWeight = 1;
  outlineColor:number[];
  backgroundColor:number[];
  d:number;
  m = 0;
  mod = 0;
  backgroundSize:number;
  orbitStop = 0;
  o: OrbitTrajectory;
  private onClick = () => undefined;
  private isClicked = false;
  private callOnClickEveryFrame = true;

  // TODO implement behavior for empty path, so we get a empty background
  constructor(p5:P5, path:string, x:number, y:number, orbitTrajectory:any, background=false, size=0, backgroundSize=0, backgroundColor:number[] = [0, 0, 0], outlineColor:number[]=[255, 255, 255], z=0) {
    super();
    this._p5 = p5;
    this.img = this._p5.loadImage(path);
    this.vector = this._p5.createVector(x, y, z);
    this.o = orbitTrajectory;
    this.backgroundColor = backgroundColor;
    this.outlineColor = outlineColor;
    this.d = size;
    this.backgroundSize = backgroundSize;
    this.background = background;
  }

  public intersectingWith(x1:number, y1:number, x2 = 0, y2 = 0):boolean {
    const xIntersect = (x1 > this.vector.x - (this.d * this.mod) / 2 && x1 < this.vector.x + (this.d * this.mod) / 2 || x2 > this.vector.x - (this.d * this.mod) / 2 && x2 < this.vector.x + (this.d * this.mod) / 2);
    const yIntersect = (y1 > this.vector.y - (this.d * this.mod) / 2 && y1 < this.vector.y + (this.d * this.mod) / 2 || y2 > this.vector.y - (this.d * this.mod) / 2 && y2 < this.vector.y + (this.d * this.mod) / 2);

    return xIntersect && yIntersect;
  }

  /**
   * a function to be called when planet is clicked on
   *
   * @param {() => any} a function to call when clicked
   * @param {boolean} [callEveryFrame=false] if passed function should be
   * called every frame. WARNING! If set to true, don't make calls to
   * external resources as it can effectivly ddos the resource.
   * @memberof Planet
   */
  public setOnClick(a:() => any, callEveryFrame=false):void {
    this.onClick = a;
    this.callOnClickEveryFrame = callEveryFrame;
  }

  public clicked():void {
    const p5 = this._p5;

    if (p5.dist(p5.mouseX, p5.mouseY, this.vector.x, this.vector.y) < this.d * this.mod) {
      this.onClick();
      this.isClicked = this.callOnClickEveryFrame;
    } else {
      this.isClicked = false;
    }
  }

  draw():void {
    const p5 = this._p5;

    if(this.o != null) {
      this.o.updateTrajectory();
      this.orbit(this.o.x, this.o.y, this.o.speed, this, this.o.width * sizeModifier, this.o.height * sizeModifier, this.o.zOrbit);
    }
    p5.push();

    if(this.isClicked) {
      this.onClick();
      this.orbitStop = 10;
    }

    p5.stroke(this.outlineColor[0], this.outlineColor[1], this.outlineColor[2]);
    p5.strokeWeight(this.outlineWeight);
    p5.fill(this.backgroundColor[0], this.backgroundColor[1], this.backgroundColor[2]);
    if(this.d > 0) {
      this.background && p5.circle(this.vector.x, this.vector.y, (this.d + this.backgroundSize) * this.mod);
      p5.image(this.img, this.vector.x - (this.d * this.mod) / 2, this.vector.y - (this.d * this.mod) / 2, this.d * this.mod, this.d * this.mod);
    } else {
      this.background && p5.circle(this.vector.x, this.vector.y, (this.img.height + this.backgroundSize) * this.mod);
      p5.image(this.img, this.vector.x - (this.img.width * this.mod) / 2, this.vector.y - (this.img.height * this.mod) / 2);
    }

    p5.pop();
  }

}

export class Moon extends Orbit {
    _p5:P5;
    vector:P5.Vector;
    d:number;
    m:number;
    mod:number;
    outlineColor:number[];
    outline:boolean;
    o:OrbitTrajectory;
    orbitStop = 0;
    onClick = ():void => undefined;


    constructor(p5:P5, trajectory:OrbitTrajectory, d:number, z = 0, outline=false, outlineColor=[0, 0, 0]) {
      super();
      this._p5 = p5;
      this.o = trajectory;
      this.vector = this._p5.createVector(this.o.x, this.o.y, z);
      this.d = d;
      this.m = 0;
      this.mod = 0;
      this.outlineColor = outlineColor;
      this.outline = outline;
    }

    public intersectingWith(x1:number, y1:number, x2 = 0, y2 = 0):boolean {
      const xIntersect = (x1 > this.vector.x - (this.d * this.mod) / 2 && x1 < this.vector.x + (this.d * this.mod) / 2 || x2 > this.vector.x - (this.d * this.mod) / 2 && x2 < this.vector.x + (this.d * this.mod) / 2);
      const yIntersect = (y1 > this.vector.y - (this.d * this.mod) / 2 && y1 < this.vector.y + (this.d * this.mod) / 2 || y2 > this.vector.y - (this.d * this.mod) / 2 && y2 < this.vector.y + (this.d * this.mod) / 2);

      return xIntersect && yIntersect;
    }

    public draw():void {
      const p5 = this._p5;

      if(this.o != null) {
        this.o.updateTrajectory();
        this.orbit(this.o.x, this.o.y, this.o.speed, this, this.o.width * sizeModifier, this.o.height * sizeModifier, this.o.zOrbit);
      }
      p5.push();

      if(this.outline) p5.stroke(this.outlineColor[0], this.outlineColor[1], this.outlineColor[2]);

      p5.scale(this.mod / 2);

      const totalSize = this.d + this.m;
      p5.circle(this.vector.x* ( 1 / (this.mod / 2) ), this.vector.y* ( 1 / (this.mod / 2) ), totalSize);
      p5.pop();
    }
}

export class Sun {
  _p5:P5;
  vector:P5.Vector;
  d: number;
  private rotation = 0;
  type: string;
  mod = 0;
  onClick = ():any => undefined;

  constructor(p5:P5, x:number, y:number, d:number, type="normal", z=0) {
    this._p5 = p5;
    this.vector = this._p5.createVector(x, y, z);
    this.d = d;
    this.type = type;
  }

  public intersectingWith(x1:number, y1:number, x2 = x1, y2 = y1):boolean {
    const xIntersect = (x1 > this._p5.windowWidth / 2 - ((this.vector.x + this.d) * this.mod * 1.3 ) / 2 && x1 < this._p5.windowWidth / 2 + ((this.vector.x + this.d) * this.mod * 1.3/ 2) || x2 > this._p5.windowWidth / 2 - ((this.vector.x + this.d) * this.mod * 1.3 ) / 2 && x2 < this._p5.windowWidth / 2 + ((this.vector.x + this.d) * this.mod * 1.3/ 2));
    const yIntersect = (y1 > this._p5.windowHeight / 2 - ((this.vector.y + this.d) * this.mod * 1.3 ) / 2 && y1 < this._p5.windowHeight / 2 + ((this.vector.y + this.d) * this.mod * 1.3/ 2) || y2 > this._p5.windowHeight / 2 - ((this.vector.y + this.d) * this.mod * 1.3 ) / 2 && y2 < this._p5.windowHeight / 2 + ((this.vector.y + this.d) * this.mod * 1.3/ 2));

    return xIntersect && yIntersect;
  }

  draw():void {
    const p5 = this._p5;
    p5.push();
    p5.rectMode(p5.CENTER);
    p5.translate(p5.windowWidth / 2, p5.windowHeight / 2);
    p5.angleMode(p5.DEGREES);
    p5.noStroke();
    p5.fill(244, 255, 0);

    if(this.type === "spikes") {
      const size = this.d * this.mod;
      p5.circle(0, 0, size);
      for (let i = 0, a = 0; i < 10; i++, a += 40) {
        const ran = Math.random() * 2;
        triangleAtPoint(p5, (size * 0.93) / 2 - ran, 0, size * 0.4 - ran, a + this.rotation);
      }
      this.rotation += 0.1;
    } else if(this.type === "normal") {
      let rot = 5.625 + (this.rotation += 0.015);
      p5.rect(this.vector.x, this.vector.y, this.d * this.mod, this.d * this.mod);
      for (let i = 0; i < 15; i++) {
        p5.rotate(rot);
        p5.rect(this.vector.x, this.vector.y, this.d * this.mod, this.d * this.mod);
        rot += 5.625;
      }
    }
    p5.pop();
  }
}

export class Star {
  _p5:P5;
  vector: P5.Vector;
  size: number;
  onClick = ():any => undefined;

  constructor(p5:P5, x:number, y:number, size = 1) {
    this._p5 = p5;
    this.vector = this._p5.createVector(x, y);
    this.size = size;
  }

  draw():void {
    const p5 = this._p5;

    p5.push();
    p5.scale(this.size);
    p5.point(this.vector.x * ( 1 / this.size ), this.vector.y * ( 1 / this.size ));
    p5.pop();
  }
}