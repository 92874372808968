import { Sketch } from "react-p5-wrapper";
import {Sun, Moon, Star, Planet, OrbitTrajectory} from "./Stars";
import {CVTEXT, LNTEXT, GITHUBTEXT, CODEWARSTEXT} from "./Text";


let sun:Sun;
let gitMoon:Moon;
let starArray:Star[] = [];
let planetGit:Planet;
let planetLn:Planet;
let planetCodewars:Planet;
let cvImg:Planet;
let pluto:Planet;
let planets:any[] = [];
export let sizeModifier = 0;
const orbitPause = 70;

export const sketch:Sketch = p5 => {

  p5.setup = () => {
    p5.createCanvas(p5.windowWidth, p5.windowHeight - 4);
    planets = [
      sun = new Sun(p5, 0, 0, 45, "normal"),
      cvImg = new Planet(p5, "/images/cv-file.svg", p5.windowWidth / 2, p5.windowHeight / 2, null, false, 35),
      planetCodewars = new Planet(p5, "/images/codewars-logo.svg", p5.windowWidth / 2, p5.windowHeight / 2, new OrbitTrajectory(p5, "center", 90, 0.015 ), true, 15, 7, [255, 0, 0], [255, 0, 0]),
      planetLn = new Planet(p5, "/images/ln-logo.png", p5.windowWidth / 2, p5.windowHeight / 2, new OrbitTrajectory(p5, "center", 140, 0.009), true, 20, 3, [255, 255, 255]),
      planetGit = new Planet(p5, "/images/github-logo.png", p5.windowWidth / 2, p5.windowHeight / 2, new OrbitTrajectory(p5, "center", 240, 0.005), true, 20, 5, [255, 255, 255]),
      gitMoon = new Moon(p5, new OrbitTrajectory(p5, "object", 5, 0.04, {obj: planetGit, width: 40, height: 15, zOrbit: true} ), 5, 0 ),
      pluto = new Planet(p5, "/images/pluto.png", p5.windowWidth / 2, p5.windowHeight / 2, new OrbitTrajectory(p5, "center", 440, 0.0010 ), true, 17),
    ];
    setSizeModifier();
    makeStars(p5.windowWidth / 10);
    p5.textFont("Orbitron");

    planetGit.setOnClick(()=> sendToWebsite("https://www.github.com/Nietzschemon"));
    //planetGit.setOnClick(()=> textBox(GITHUBTEXT, planetGit.vector.x, planetGit.vector.y, 14, planetGit.backgroundColor));
    planetLn.setOnClick(()=> sendToWebsite("https://www.linkedin.com/in/stefan-ekblom-2a5a99191/"));
    //planetLn.setOnClick(()=> textBox(LNTEXT, planetLn.vector.x, planetLn.vector.y, 14, [0, 160, 220]));
    pluto.setOnClick(()=> sendToWebsite("https://www.youtube.com/watch?v=dQw4w9WgXcQ"));
    planetCodewars.setOnClick(()=> sendToWebsite("https://www.codewars.com/users/Logomon"));
    //planetCodewars.setOnClick(()=> textBox(CODEWARSTEXT, planetCodewars.vector.x, planetCodewars.vector.y, 14, planetCodewars.backgroundColor), true);
    cvImg.setOnClick(()=> textBox(CVTEXT, cvImg.vector.x + 15, cvImg.vector.y, 14, [244, 255, 0]), true);
  };

  p5.windowResized = () => {
    p5.resizeCanvas(p5.windowWidth, p5.windowHeight - 4);
    makeStars(p5.windowWidth / 10);
    cvImg.vector.x = p5.windowWidth / 2;
    cvImg.vector.y = p5.windowHeight / 2;
    setSizeModifier();
  };

  p5.draw = () => {
    p5.background(0);
    p5.color(120);
    p5.fill(255, 255, 255);
    p5.strokeWeight(5);
    p5.stroke(120, 120, 120);

    drawStars();

    drawSolarSystem();
    orbitStopper();
  };


  p5.mouseClicked = () => {
    for (const p of planets) {
      if(p.clicked != undefined) {
        p.clicked();
      }
    }
  };


  function textBox(str:string, x:number, y:number, fontSize = 14, objColor=[255, 255, 255]) {
    const marginX = p5.width / 16;
    const marginY = p5.height / 16;
    const sizeMaxX = p5.windowWidth - x - marginX;
    const sizeMaxY = p5.windowHeight - y - marginY;
    const boxSizeX = (sizeMaxX < str.length * (fontSize / 1.9)) ? sizeMaxX : str.length * (fontSize / 1.4);
    const boxSizeY = (sizeMaxY < str.split("\n").length * fontSize * 2.5) ? sizeMaxY : str.split("\n").length * fontSize * 2.5;
    const padding = 20;
    const rectColor = p5.color(objColor[0], objColor[1], objColor[2]);
    rectColor.setAlpha(240); // transparency value

    p5.push();

    p5.textSize(fontSize);
    p5.noStroke();
    p5.fill(rectColor);
    p5.rect(x, y, boxSizeX + padding, boxSizeY + padding, 20);
    p5.fill("black");
    p5.text(str, x + padding, y + padding, boxSizeX - padding, boxSizeY + padding );

    p5.pop();
  }

  function sendToWebsite(site) {
    window.location.href = site;
  }


  function drawSolarSystem() {
    planets = planets.sort((a, b) => a.vector.z - b.vector.z);

    for (const x of planets) {
      x.draw();
    }
  }

  function setSizeModifier() {
    sizeModifier = ((Math.round(p5.windowWidth) / 1000));
    planets.map((x) => x.mod = sizeModifier);
  }


  function orbitStopper() {
    for(const x of planets) {
      if(x.intersectingWith(p5.mouseX + 10 * sizeModifier, p5.mouseY + 10 * sizeModifier, p5.mouseX - 10 * sizeModifier, p5.mouseY - 10* sizeModifier) == true) {
        x.orbitStop = orbitPause;
        continue;
      }
      if(x.orbitStop > 0) x.orbitStop -= 1;
    }
  }

  function makeStars(numStars:number) {
    starArray = [];
    for (let i = 0; i < numStars; i++) {
      starArray.push(new Star(p5, Math.random() * p5.windowWidth, Math.random() * p5.windowHeight, Math.random()));
    }
  }

  function drawStars() {
    p5.stroke(255);
    starArray.forEach(e => {e.draw();});
  }
};