import React from "react";
import P5 from "../components/p5";
import { container } from "../components/css/layout.module.css";
import "../components/css/std-layout.css";
import {Menu} from "../components/Menu";


const IndexPage = ():JSX.Element => {
  return (
    <div className={container} >
      <Menu/>
      <P5/>
    </div>
  );
};

export default IndexPage;