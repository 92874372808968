import P5 from "p5";

export class Circle {
    _p5:P5;
    vector:P5.Vector;
    d:number;
    m:number;
    mod:number;
    outlineColor:number[];
    outline:boolean;
    orbit = 0;
    orbitStop = 0;


    constructor(p5:P5, x:number, y:number, d:number, z = 0, outline=false, outlineColor=[0, 0, 0]) {
      this._p5 = p5;
      this.vector = this._p5.createVector(x, y, z);
      this.d = d;
      this.m = 0;
      this.mod = 0;
      this.outlineColor = outlineColor;
      this.outline = outline;
    }

    public intersectingWith(x1:number, y1:number, x2 = 0, y2 = 0):boolean {
      const xIntersect = (x1 > this.vector.x - (this.d * this.mod) / 2 && x1 < this.vector.x + (this.d * this.mod) / 2 || x2 > this.vector.x - (this.d * this.mod) / 2 && x2 < this.vector.x + (this.d * this.mod) / 2);
      const yIntersect = (y1 > this.vector.y - (this.d * this.mod) / 2 && y1 < this.vector.y + (this.d * this.mod) / 2 || y2 > this.vector.y - (this.d * this.mod) / 2 && y2 < this.vector.y + (this.d * this.mod) / 2);

      return xIntersect && yIntersect;
    }

    public draw():void {
      const p5 = this._p5;
      p5.push();

      if(this.outline) p5.stroke(this.outlineColor[0], this.outlineColor[1], this.outlineColor[2]);

      p5.scale(this.mod / 2);

      const totalSize = this.d + this.m;
      p5.circle(this.vector.x* ( 1 / (this.mod / 2) ), this.vector.y* ( 1 / (this.mod / 2) ), totalSize);
      p5.pop();
    }
}

export class Rect {
    _p5:P5;
    vector: P5.Vector;
    w:number;
    h:number;
    d:number;

    constructor(x:number, y:number, w:number, h:number, d:number, z=0) {
      this.vector = this._p5.createVector(x, y, z);
      this.w = w;
      this.h = h;
      this.d = d;
    }

    public draw():void {this._p5.rect(this.vector.x, this.vector.y, this.w, this.h);}
}

/**
 * Creates a equilateral triangle with the x axis in the
 * middle of its left side.
 *
 * @param {number} x offset for x axis
 * @param {number} y offset for y axis
 * @param {number} size the size of the triangle
 * @param {number} [rotation=0] rotates the triangle on its x axis.
 */
export function triangleAtPoint(p5:P5, x:number, y:number, size:number, rotation=0):void {
  const x1 = x;
  const y1 = (1 * -( size / 2 )) + y;
  const x2 = x;
  const y2 = (1 * ( size / 2 )) + y;
  const x3 = size + x;
  const y3 = y;

  p5.push();
  p5.rotate(rotation);
  p5.triangle(x1, y1, x2, y2, x3, y3);
  p5.pop();
}