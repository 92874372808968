import React from "react";
import {navbarTop, btn1, btn2, btn3, btn4, navbarBottom, text} from "../components/css/menu.module.css";
import { CVTEXT } from "./Text";


export default function Menu():JSX.Element {
  return (
    <div>
      <section className={navbarTop}>
        <a className={btn1}>CV</a>
        <p className={text}>{CVTEXT}</p>
      </section>
      <section className={navbarBottom}>

        <a className={btn2} href="https://www.codewars.com/users/Logomon"><img src="./images/codewars-logo.svg"/></a>
        <a className={btn3} href="https://www.linkedin.com/in/stefan-ekblom-2a5a99191/"><img src="./images/ln-logo.svg"/></a>
        <a className={btn4} href="https://www.github.com/nietzschemon"><img src="./images/github-logo.svg"/></a>
      </section>
    </div>
  );
}

export { Menu };